<script>
	export let content = {
		title: 'ABCmouse.com',
		link: [
			{
				rel: '',
				href: '',
				hreflang: ''
			}
		],
		metaTag: [
			{
				name: '',
				content: '',
				property: '',
				href: ''
			}
		]
	};
</script>

<svelte:head>
	<title>{content.title}</title>

	{#if content.link}
		{#each content.link as linkTag}
			<link
				{...linkTag?.rel ? { rel: linkTag?.rel } : {}}
				{...linkTag?.href ? { href: linkTag?.href } : {}}
				{...linkTag?.hreflang ? { hreflang: linkTag?.hreflang } : {}}
			/>
		{/each}
	{/if}

	{#if content.metaTag}
		{#each content.metaTag as tag}
			<meta
				{...tag?.name ? { name: tag?.name } : {}}
				{...tag?.property ? { property: tag?.property } : {}}
				{...tag?.href ? { href: tag?.href } : {}}
				{...tag?.content ? { content: tag?.content } : {}}
			/>
		{/each}
	{/if}
</svelte:head>
